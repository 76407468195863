import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import NewsView from './news-view'
import { useTranslation } from 'react-i18next'

const NewsCotroller = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataNews, setDataNews] = useState([])
  const [totalPages, setTotalPages] = useState(null)
  const [totalPosts, setTotalPosts] = useState(0)
  const [pageSelect, setPageSelect] = useState(0)
  const [type, setType] = useState(null)
  const controller = new AbortController()
  const fields = 'id,title,excerpt,content,slug,acf,human_date,thumbnail,related,location,genre,featured_media'
  const [filters, setFilters] = useState({
    location_slug: null,
    genre_slug: null,
    page: 1,
    fields,
  })
  const { i18n } = useTranslation();

  // catch with useEffect so the data will be contained
  const getDataNews = async () => {
    setLoading(true)
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'posts',
      signal: controller.signal,
      params: {
        ...filters,
        slug,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataNews(resp.data)
      setTotalPages(resp.total_pages)
      setTotalPosts(resp.total_posts)
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDataNews()
    return () => {
      controller.abort()
    }
  }, [filters])

  const onChangeAllTypes = (typeOption) => {
    setDataNews([])
    setType(typeOption.value)
    let updatedValue = {}
    updatedValue = {
      genre_slug: _.toLower(typeOption.value),
      page: 1,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }
  const onChangeLocations = (location) => {
    setDataNews([])
    setType(location.value)
    let updatedValue = {}
    updatedValue = {
      location_slug: _.toLower(location.value),
      page: 1,
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleNextPage = (value) => {
    setDataNews([])
    setPageSelect(value - 1)
    let updatedValue = {}
    updatedValue = {
      page: value,
    }
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const viewProps = {
    data: dataNews,
    loading,
    type,
    onChangeAllTypes,
    onChangeLocations,
    onHandleNextPage,
    totalPages,
    totalPosts,
    pageSelect,
  }
  return (
    <NewsView {...viewProps}/>
  )
}

export default NewsCotroller
