import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  HeaderLintingPages,
  Seo,
  Spinner,
  ListView,
  CardListHoz,
  GradientBanner,
} from "../../components"
import { useContentFilters } from "../../hooks/use-content-filters"
import { getOptionsSelect } from "../../helpers"
import "./news-styles.scss"

const NewsView = ({
  loading,
  data,
  onChangeAllTypes,
  onChangeLocations,
  totalPages,
  totalPosts,
  onHandleNextPage,
  pageSelect,
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const { genreFilter, locations } = useContentFilters()

  useEffect(() => {
    const selectOptions = [
      {
        options: getOptionsSelect(genreFilter, t("news.all-types")),
        placeholder: t("news.placeholder-types"),
        onChange: onChangeAllTypes,
      },
      {
        options: getOptionsSelect(locations, t("news.all-locations")),
        placeholder: t("news.placeholder-locations"),
        onChange: onChangeLocations,
      },
    ]
    setOptions(selectOptions)
  }, [genreFilter, locations])

  return (
    <>
      <div className="container-fluid news__container">
        <Seo title="News" />
        <HeaderLintingPages title={t("news.title")} selectOptions={options} />
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <ListView
            t={t}
            data={data}
            card={CardListHoz}
            classN="col-12 news__col"
            totalPosts={totalPosts}
            onHandleNextPage={onHandleNextPage}
            totalPages={totalPages}
            pageSelect={pageSelect}
          />
        )}
      </div>
      <GradientBanner />
    </>
  )
}

export default NewsView
