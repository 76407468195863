import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  PostTemplate,
  Spinner,
  Carousel,
  CardListVertical,
  GradientBanner,
} from '../../components'
import './news-styles.scss'

const NewsDetailView = ({
  data,
  loading,
}) => {
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <React.Fragment>
      <div className="newsDetail__container">
        <div className="newsDetail__content">
          <PostTemplate data={data} t={t} />

        </div>
      </div>
      <div className='newsDetail__carousel'>
        <div>
          {data.related ? (

            <div className="">
              <div className="carousel__tagline">
                <h2>{t('newsDetail.related')}</h2>
              </div>
              <div>
                <Carousel
                  data={data.related}
                  sToShow={3}
                  sToScroll={3}
                  arrows={true}
                >
                  {data.related.map((news, i) => <div key={i} className="cardTeam__box">
                    <CardListVertical
                      data={news}
                    />
                  </div>)}
                </Carousel>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <GradientBanner />
    </React.Fragment>

  )
}

export default NewsDetailView
